import React, { useContext, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import './styles.css';
import { AuthContext } from '../../context/auth';
import { Navigate } from 'react-router-dom';

function Login() {
    const loginRef = useRef(null);
    const passwordRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const {signIn, signed, msg} = useContext(AuthContext)

    useEffect(() => {
        document.title = 'CCTMontana - Login';
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault()
        setLoading(true);
        
        let data = {
            login: loginRef?.current?.value.replace(/\D/g, ''),
            password: passwordRef?.current?.value
        }
        
        await signIn(data)
        setLoading(false);
    };
    
    if(!signed) {

        return (
            <div className='container-login'>
            <div className='login-box'>
                <label htmlFor='cpf'>CPF:</label>
                <InputMask
                    mask='999.999.999-99'
                    maskChar={null}
                    placeholder='Digite seu CPF'
                    id='cpf'
                    pattern='\d{11}'
                    ref={loginRef}
                    style={{
                        width: '98%',
                        padding: '10px',
                        borderRadius: '10px',
                        borderWidth: '1px',
                        borderColor: '#ccc',
                        marginBottom: '10px',
                    }}
                    />

                <label htmlFor='password'>Senha:</label>
                <input
                    type='password'
                    id='password'
                    placeholder='Digite sua senha'
                    ref={passwordRef}
                    style={{
                        width: '98%',
                        padding: '10px',
                        borderRadius: '10px',
                        borderWidth: '1px',
                        borderColor: '#ccc',
                        marginBottom: '10px',
                    }}
                    />
                <>{msg ? <div className='erro-msg'>{msg}</div> : ''}</>
                <button
                    onClick={handleLogin}
                    disabled={loading}
                    style={{
                        width: '100%',
                        padding: '10px',
                        background: loading ? '#ddd' : '#085625',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                    >
                    {loading ? 'Aguarde...' : 'Acessar'}
                </button>
            </div>
        </div>
    )}
    else {
        return <Navigate to="/usuario" />
    }
}

export default Login;