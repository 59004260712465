import React, { useState, useEffect } from 'react';
import { FaMedal } from 'react-icons/fa'; 
import  './styles.css';


function Eventos() {
  const [data, setData] = useState({});

  const getData = async () => {
    await fetch('./data.json', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
    .then(response => response.json())
    .then(data => {
      data.categorias.map(item => {
        if (item.ordem === "asc") {
          item.pontuacoes.sort((a, b) => a.pts - b.pts);
        } else if (item.ordem === "desc") {
          item.pontuacoes.sort((a, b) => b.pts - a.pts);
        }
      });
      setData(data);
    })
    .catch(error => console.error(error));
  }

  const get_medal = (pos) => {
    if (pos <= 3) return "medal-icon"+pos
    return "medal-iconx"
  }

  useEffect(() => {
    document.title = 'CCTMontana - Eventos';
    getData()
  }, [])

  return (
    <div className='evento-container'>
      {(Object.keys(data).length === 0) || (!data.show) ? (
        <h2>Não há eventos</h2>
      ) : (
        <div className='ranking'>
          <div>
            <h2 className='estilo2' style={{margin: '15px 0px'}}>{data.desc}</h2>
            <h3 className='estilo3'>Data: {data.data}</h3>
              <h3 className='estilo3' style={{marginBottom: '15px' }}>Colocações:</h3>
          </div>
          {
            data.categorias.map((item) => {
              return <div>
                <h6>{item.desc}</h6>
                {item.pontuacoes.map((p, index) => {
                  return <div key={index} className='item'>
                    <div style={{minWidth:'80%', paddingRight: '5px'}}>
                      {index < data.premiacao_ate && (
                        <span className={get_medal(index+1)}>
                          <FaMedal />
                        </span>
                      )}
                      {index+1}° {p.nome} 
                    </div>
                    <div>
                      <span className='estilo-pts'>Pontuação:</span> {p.pts.toFixed(3)}
                    </div>
                  </div>
                })}
                <br />
              </div>
            })
          }
        </div>
      )}
    </div>
  );
}

export default Eventos;