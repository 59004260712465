// Usuario.js
import React, { useEffect, useState } from 'react';

import Arma from '../../components/arma'

import './styles.css';

function Usuario() {
    const [userInfo, setUserInfo] = useState({
        inscricao: '0399',
        dataInscricao: '10/10/2020',
        nome: 'Nome do Usuário',
        cpf: '123.456.789-00',
        telefone1: '(12) 3456-7890',
        telefone2: '(98) 7654-3210',
        cr: {
            numero: '789012',
            data_emissao: '10/10/2020',
            data_validade: '10/10/2030'

        },
        endereco: {
            numero: '123',
            rua: 'Rua Exemplo',
            bairro: 'Bairro Teste',
            cidade: 'Cidade Modelo',
        },
    });

    const [armas, setArmas] = useState([
        {
            numeroRegistro: 'AR123',
            tipo: 'Pistola',
            modelo: 'Glock 19',
            num_serie: 'ABC124',
            calibre: '9mm',
            num_craf: '12345',
            craf_data_exp: '2023-01-20',
            craf_data_validade: '2025-01-20',
            num_sigma: '67890',
            guia_num: 'G123456',
            guia_trafego_exp: '2023-01-25',
            guia_trafego_validade: '2025-01-25',
        },
        {
            numeroRegistro: 'AR123',
            tipo: 'Pistola',
            modelo: 'Glock 27',
            num_serie: 'ABC124',
            calibre: '9mm',
            num_craf: '12345',
            craf_data_exp: '2023-01-20',
            craf_data_validade: '2025-01-20',
            num_sigma: '67890',
            guia_num: 'G123456',
            guia_trafego_exp: '2023-01-25',
            guia_trafego_validade: '2025-01-25',
        },
        {
            numeroRegistro: 'AR123',
            tipo: 'Pistola',
            modelo: 'Glock 37',
            num_serie: 'ABC124',
            calibre: '9mm',
            num_craf: '12345',
            craf_data_exp: '2023-01-20',
            craf_data_validade: '2025-01-20',
            num_sigma: '67890',
            guia_num: 'G123456',
            guia_trafego_exp: '2023-01-25',
            guia_trafego_validade: '2025-01-25',
        }
        // Adicione mais armas conforme necessário
    ]);

    useEffect(() => {
        document.title = 'CCTMontana Sistema - Usuario';
    }, []);

    var renderArmas = () => {
        return armas.map((arma, index) => (
            <Arma arma={arma} index={index}/>
        ))
    }
    
    return (
        <div className="usuario-container">
            <div className="info-retangulo">
                <div className="titulo">Informações do Sócio:</div>
                <div className="info-row">
                    <div>
                        <strong>N° Incrição:</strong> {userInfo.inscricao}
                    </div>
                    <div>
                        <strong>Data da Incrição:</strong> {userInfo.dataInscricao}
                    </div>
                </div>
                <div className="info-row">
                    <div>
                        <strong>N° CR:</strong> {userInfo.cr.numero}
                    </div>
                    <div>
                        <strong>Emitido em:</strong> {userInfo.cr.data_emissao}
                    </div>
                    <div>
                        <strong>Valido até:</strong> {userInfo.cr.data_emissao}
                    </div>
                </div>
                <div className="info-row">
                    <div>
                        <strong>Nome:</strong> {userInfo.nome}
                    </div>
                    <div>
                        <strong>CPF:</strong> {userInfo.cpf}
                    </div>
                    <div>
                        <strong>Telefone1:</strong> {userInfo.telefone1}
                    </div>
                    <div>
                        <strong>Telefone2:</strong> {userInfo.telefone2}
                    </div>
                </div>
                <div className="titulo">Endereço:</div>
                <div className="info-row">
                    <div>
                        <strong>Número:</strong> {userInfo.endereco.numero}
                    </div>
                    <div>
                        <strong>Rua:</strong> {userInfo.endereco.rua}
                    </div>
                    <div>
                        <strong>Bairro:</strong> {userInfo.endereco.bairro}
                    </div>
                    <div>
                        <strong>Cidade:</strong> {userInfo.endereco.cidade}
                    </div>
                </div>
            </div>
            
            {armas.length <= 0 ? <div> <strong> Não há armas cadastradas </strong></div> : renderArmas() }
        </div>
           
    );
}

export default Usuario;
