import React, { useEffect } from 'react';

import './styles.css';

function Sobre() {
  
  useEffect(() => {
    document.title = 'CCTMontana - Sobre';
  }, []);
 
  return <div className='sobre-container'>
    <div className='sobre-item'>
      <h1 className='estilo1'>Sobre o CCT Montana - Clube de Tiro</h1>
      <p className='estilo2'> 
        Bem-vindo ao CCT Montana, um refúgio para os entusiastas de tiro e amantes da natureza! <br /><br />
        Localizado na região de Itaicoca, nosso clube é um destino excepcional 
        que combina a adrenalina da prática de tiro com a serenidade da vida ao ar livre.
      </p> 
    </div>
    <div className='sobre-item'>
      <h1 className='estilo1'>Nossa História</h1>
      <p className='estilo2'> Fundado em 2020 por um apaixonado por armas e pela beleza natural,
        o CCT Montana surgiu como um lugar onde os amantes do tiro pudessem se reunir,
        aprimorar suas habilidades, compartilhar conhecimento e desfrutar de todas as maravilhas que a região tem a oferecer. <br /><br />
        Estamos comprometidos em promover a segurança, a responsabilidade e a educação no manuseio de armas de fogo.
        </p>
    </div>
    <div className='sobre-item'>
      <h1 className='estilo1'>Instalações</h1>
      <p className='estilo2'>
        Nosso clube oferece uma ampla gama de instalações para atender às necessidades de todos os membros: <br /> <br />
        <ul>
          <li><b>Pistas de Tiro Iluminadas:</b> Desafie-se e aprimore suas habilidades de tiro em nossas pistas modernas e iluminadas,
            permitindo que você desfrute da prática noturna com segurança.</li>
          <li><b>Área de Camping:</b> Aprecie a beleza natural em nossa área de camping,
            onde você pode relaxar sob o céu estrelado e se conectar com a natureza.</li>
          <li><b>Área de Pesca:</b> Explore nossos lagos e rios para uma emocionante experiência de pesca,
            cercada pela tranquilidade da natureza.</li>
        </ul>
      </p>
    </div>
    <div className='sobre-item'>
      <h1 className='estilo1'>Comunidade e Eventos</h1>

      <p className='estilo2'>No CCT Montana, não somos apenas um clube, somos uma comunidade unida de entusiastas que compartilham
        uma paixão por esportes ao ar livre e pelo tiro. <br /><br/> Organizamos uma série de eventos,
        competições e atividades sociais ao longo do ano para promover o companheirismo e o aprendizado contínuo.</p>
    </div>
    <div className='sobre-item'>
      <h1 className='estilo1'>Compromisso com a Segurança</h1>
      <h2 className='estilo2'>A segurança é a nossa prioridade número um!</h2>
      <p className='estilo2'>
        Nossos membros são incentivados a seguir rigorosamente as diretrizes de segurança e a aderir a um código de conduta responsável ao manusear armas de fogo.
      </p>
    </div>
    <div className='sobre-item'>
      <h1 className='estilo1'>Torne-se um Membro</h1>
      <p className='estilo2'>
        Estamos sempre ansiosos para receber novos membros em nossa comunidade.<br /><br />
        Se você compartilha nosso amor pelo tiro e pela natureza, junte-se a nós
        no CCT Montana para uma experiência única e gratificante.<br /><br />
        Explore as inúmeras oportunidades que oferecemos para aprimorar suas habilidades de tiro,
        aproveitar a vida ao ar livre e fazer parte de uma comunidade apaixonada.
        <br/>
      </p>
      <p className='estilo2'>Venha nos visitar!<br /><br /></p>
    </div>
  </div>
}

export default Sobre;