import React from 'react';

import './styles.css';

function Arma({arma, index}) {
    return <div className="info-retangulo">
        <div className="titulo">Informações sobre {arma.modelo}:</div>
        <div key={index} className="info-row">
            <div>
                <strong>N° Registro:</strong> {arma.numeroRegistro}
            </div>
            <div>
                <strong>Tipo:</strong> {arma.tipo}
            </div>
            <div>
                <strong>Modelo:</strong> {arma.modelo}
            </div>
            <div>
                <strong>N° Série:</strong> {arma.num_serie}
            </div>
            <div>
                <strong>Calibre:</strong> {arma.calibre}
            </div>
        </div>
        <div key={index + '$'} className="info-row" style={{ justifyContent: "start" }}>
            <div>
                <strong>N° CRAF:</strong> {arma.num_craf}
            </div>
            <div>
                <strong>Exp. CRAF:</strong> {arma.craf_data_exp}
            </div>
            <div>
                <strong>Validade CRAF:</strong> {arma.craf_data_validade}
            </div>
        </div>
        <div key={index + '%'} className="info-row" style={{ justifyContent: "start" }}>
            <div>
                <strong>N° Sigma:</strong> {arma.num_sigma}
            </div>
            <div>
                <strong>N° Guia:</strong> {arma.guia_num}
            </div>
            <div>
                <strong>Exp. Guia:</strong> {arma.guia_trafego_exp}
            </div>
            <div>
                <strong>Validade Guia:</strong> {arma.guia_trafego_validade}
            </div>
        </div>
    </div>
}

export default Arma;