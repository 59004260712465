import { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { api } from '../services/api.js'

export const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [msg, msgSet] = useState('')

    useEffect(()=> {
        const loadingStoreData = () => {
            const storageUser = localStorage.getItem("@Auth:user")
            const storageToken = localStorage.getItem("@Auth:token")
            
            if( storageUser && storageToken){
                setUser(storageUser)
            }
        };
        loadingStoreData()
    }, [])

    const signIn = async ({ login, password }) => {
        try {
            const response = await api.post("/login", { login, password });
            //const response = {data: {user:'usuarioMaster', token: 'asdas123123'}}

            if (response.data.error) {
                msgSet(response.data.error);
            } else {
                setUser(response.data);

                api.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;

                localStorage.setItem("@Auth:user", JSON.stringify(response.data.user));
                localStorage.setItem("@Auth:token", response.data.token);
            }
        } catch (error) {
            msgSet("Verifique sua conexão com a internet")
            console.log("erro>>",error);
        }
    };

    const signOut = () => {
        localStorage.clear();
        setUser(null);
        return <Navigate to="/" />;
    };

    return (
        <AuthContext.Provider value={{
            user,
            signIn,
            signOut,
            msg,
            signed: !!user
        }}>
            {children}
        </AuthContext.Provider>
    )
}