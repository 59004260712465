
export const navbarData = [
    {
        icon: '',
        text: 'Inicio',
        path: '/'
    },
    {
        icon: '',
        text: 'Sobre',
        path: '/sobre'
    },
    {
        icon: '',
        text: 'Eventos',
        path: '/eventos'
    },
    {
        icon: '',
        text: 'Contatos',
        path: '/contatos'
    }
];