import React from "react";
import {
  Routes,
  Route
} from "react-router-dom";

import Main from "../pages/Main"
import Contatos from "../pages/Contatos";
import Eventos from "../pages/Eventos";
import Sobre from "../pages/Sobre";

import Login from "../pages/Login";
import Usuario from "../pages/Usuario";

import { PrivateRoute } from "./privateRouters";

export function AppRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<Main />}/>
      <Route exact path="/inicio" element={<Main />}/>
      <Route exact path="/contatos" element={<Contatos />}/>
      <Route exact path="/eventos" element={<Eventos />}/>
      <Route exact path="/sobre" element={<Sobre />}/>
      
     
      <Route exact path="/login" element={<Login />}/>
      
      <Route exact path="/usuario" element={<PrivateRoute />}>
        <Route exact path="/usuario" element={<Usuario />}/>
      </Route>
    

      <Route exact path="*" element={<Main />}/>
    </Routes>
  );
}