import React, {useEffect} from 'react';

import './styles.css';

function Contatos() {
  useEffect(() => {
    document.title = 'CCTMontana - Contatos';
  }, [])
  return (
    <div className="contato-container" >
      <div className="contato-map-link">
        <a target="_blank" rel="noreferrer" href="https://www.google.com.br/maps/dir//CCT+Montana+-+Clube+de+Ca%C3%A7a+e+Tiro+-+Estr.+Pery+Pereira+Costa,+PR-513,+km+10+-+Cerrado+Grande,+Ponta+Grossa+-+PR,+84033-260/@-25.149295,-49.8704702,14.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x94c2ade02ff0149f:0x7ec557ea9ba1253c!2m2!1d-49.8739773!2d-25.1519456!3e0">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
          </svg><p className='estilo-rota'>Clique aqui para gerar sua rota </p>
        </a>
      </div>
      <div className="contato-map">
        <iframe className="frame" 
          title='map' 
          src="https://www.google.com/maps/embed?pb=!1m24!1m12!1m3!1d169511.41198978067!2d-50.026876277704744!3d-25.12023071176269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m9!3e0!4m3!3m2!1d-25.0887235!2d-50.0878727!4m3!3m2!1d-25.1451852!2d-49.873388899999995!5e1!3m2!1sen!2sbr!4v1602286226127!5m2!1sen!2sbr" 
          width="95%" 
          height="400" 
          frameborder="0" 
          allowfullscreen="" 
          aria-hidden="false" 
          tabindex="0">
        </iframe>
      </div>
      <div className="contato-list">
        <div className="contato-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"></path>
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
          </svg>
          <h2 className="estilo2">Endereço</h2>
          <p className='estilo3'>Estrada Pery Pereira Costa PR-513, km 10 <br /> Cerrado Grande, Ponta Grossa - PR<br /> CEP 84033-260 </p>
          
        </div>
        <div className="contato-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
          </svg>
          <h2 className="estilo2">Horários</h2>
          <p className='estilo3'>Terça à Sexta-feira das 13h00 às 18h00<br />Sábado das 08h00 às 18h00<br />Domingo das 08h00 às 16h00</p>
          <p className='estilo4'>(18h as 22h com agendamento)</p>
          
        </div>
        <div className="contato-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
          </svg>
          <h2 className="estilo2">Contato</h2>
          <a href="https://wa.me/5542999196676" target="_blank" rel="noreferrer" > <b> WhatsApp <br/> (42) 9 9919-6676</b></a>
        </div>
      </div>
    </div>
  )
}

export default Contatos;