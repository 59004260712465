import React from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";

import './styles.css';
import { navbarData } from './navbarData'
import logo_pp from '../../assets/logo_cct_white_pp.png'

function Navbar() {
  let location = useLocation();
  return (
    <div className='navbar-container'> 
      <Link to="/"> <img src={logo_pp} alt="Logo" /> </Link>
      { 
        navbarData.map((item) => {
          return <div key={item.text} className='navbar-item'>
            <Link className='navbar-link' style={location.pathname === item.path ? {fontWeight: "bold"}: {} } to={item.path}> {item.text} </Link>
          </div>
        })
      }
    </div>
  );
}

export default Navbar;