import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import { App } from './App.js';
import NavBar from './components/navbar'
import Footer from './components/footer'

import './global.css'

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <NavBar />
      <App />
      <Footer />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
