import React, {useEffect, useState} from 'react';

import './styles.css';
import banner_img from '../../assets/fundo_banner.jpg'
import logo_black_p from '../../assets/logo_cct_black_p.png'

import carrossel_img1 from '../../assets/cctm1.jpeg';
import carrossel_img2 from '../../assets/cctm2.jpeg';
import carrossel_img3 from '../../assets/cctm3.jpeg';
import carrossel_img4 from '../../assets/cctm4.jpeg';
import carrossel_img5 from '../../assets/cctm5.jpeg';
import carrossel_img6 from '../../assets/cctm6.jpeg';
import carrossel_img7 from '../../assets/cctm7.jpeg';
import carrossel_img8 from '../../assets/cctm8.jpeg';

const carrosselImages = [carrossel_img1, 
  carrossel_img2,
  carrossel_img3, 
  carrossel_img4,
  carrossel_img5,
  carrossel_img6,
  carrossel_img7,
  carrossel_img8,
];

function Main() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    document.title = 'CCTMontana';
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % carrosselImages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='main-container' >
      <div className='banner' style={{ backgroundImage: `url(${banner_img})` }} >
        <img className='logo' src={logo_black_p} alt="Logo" />
      </div>
      <div className='content'>
        <div className='apresentacao'>
          <p>
          Bem-vindo ao CCT Montana, o destino perfeito para entusiastas de tiro e amantes da natureza. 
          Localizado em Itaicoca, nosso clube, fundado em 2020, combina adrenalina e serenidade, oferecendo pistas de tiro iluminadas, 
          áreas de camping e espaços de pesca. 
          Promovemos segurança, responsabilidade e educação no manuseio de armas de fogo, organizando eventos e competições que 
          fortalecem nossa comunidade unida. Se você compartilha nossa paixão pelo tiro e pela vida ao ar livre, junte-se a nós no CCT Montana 
          e aproveite uma experiência única e gratificante.
          </p>
        </div>
        <div className='carrossel'
          style={{ backgroundImage: `url(${carrosselImages[currentImageIndex]})` }}
        > 
        </div>
      </div>
    </div>
  )
}

export default Main;
